/**

	@Projekt: 	
	@Autor:   	LEMONIT
	@Zakodował: MK
	@Data:      11/2017
	-------------------
	SKRYPTY.JS
	-------------------

**/

var LEMON = {
	
	carousels:function(){
		var $autoplayTime = 10000;
		
		$('.homepage-slider-carousel').owlCarousel({
			autoplay: true,
			autoplayHoverPause: false,
			autoplayTimeout: $autoplayTime,
			dots: true,
			dotsEach: true,
			dotsContainer: '.homepage-slider-dots',
			items: 1,
			loop: true,
			nav: true,
			navContainer: '.homepage-slider-controls', 
			navText: ['<i class="icon-lewo"></i>','<i class="icon-prawo"></i>'],
			mouseDrag: true,
			smartSpeed: 500,
			responsive: {
				0: {
					autoHeight: false
				},
				768: {
					autoHeight: true
				}
			},
			onTranslate: function(event){
				$('.homepage-slider-carousel').trigger('stop.owl.autoplay');
			},
			onTranslated: function(event){
				$('.homepage-slider-carousel').trigger('play.owl.autoplay',[$autoplayTime]);
			}
		});
		
		$('.homepage-logos-carousel').owlCarousel({
			autoplay: true,
			autoplayHoverPause: true,
			autoplayTimeout: $autoplayTime,
			dots: false,
			items: 4,
			loop: true,
			margin: 60,
			nav: true,
			navContainer: '.homepage-logos-controls', 
			navText: ['<i class="icon-lewo"></i>','<i class="icon-prawo"></i>'],
			mouseDrag: true,
			smartSpeed: 500,
			responsive: {
				0: {
					items: 1,
					margin: 0
				},
				380: {
					items: 2,
					margin: 0
				},
				568: {
					items: 3,
					margin: 0
				},
				992: {
					items: 4
				}
			},
			onTranslate: function(event){
				$('.homepage-logos-carousel').trigger('stop.owl.autoplay');
			},
			onTranslated: function(event){
				$('.homepage-logos-carousel').trigger('play.owl.autoplay',[$autoplayTime]);
			}
		});
	},
	flowType:function(){	
		$('.homepage-slider .item-text p').flowtype({
			minFont : 14
		});
	},
	map:function(){
		var styles = [{

		}];
		if($('.contact-map').length > 0){
			function __create_map_single() {
				var data = JSON.parse($('.contact-map').attr('data-mapa'));
				var map = new GMaps({
					div : '.contact-map',
					lat : data.lokalizacja.lat,
					lng : data.lokalizacja.lng,
					zoom : data.lokalizacja.zoom,
					enableNewStyle: true,
					scrollwheel : false,
					navigationControl : true,
					mapTypeControl : true,
					scaleControl : true,
					draggable : true,
          zoomControl: true,
          mapTypeControl: true
				});

				if(data.obiekty[1].nazwa){
					map.addMarker({
						lat : data.obiekty[1].lat,
						lng : data.obiekty[1].lng,
						infoWindow : {
							content : data.obiekty[1].nazwa
						}
					});
				} else {
					map.addMarker({
						lat : data.obiekty[1].lat,
						lng : data.obiekty[1].lng
					});
				}
			}
			
		$(window).resize(function(){
			var w = $(window).width();
			if (typeof checkw == 'undefined') checkw = w;
			if (w!=checkw) {
					__create_map_single();
					checkw = w;
			}
		});
			__create_map_single();
		}
	},
	menu:function(){
		var $toggle = $('.hamburger'),
				$aside  = $('aside');
		
		$toggle.click(function(e){
			$aside.toggleClass('active');
			$(this).toggleClass('is-active');
			return false;
		});
		
		$('.aside-menu li a').click(function(){
			$('.hamburger').removeClass('is-active');
			$('aside').removeClass('active');
		});
		
		$(".aside-container").mCustomScrollbar({
			theme: "dark"
		});
	},
	sameHeight:function(){
		function sameheight(div){
			$(document).find(div).css({"min-height":"auto"});

			var largest = 0;
			var findHeight = 0;


			if($(window).width() > 480){
				$(document).find(div).each(function(){
					$(this).css({"min-height":"0px"});
					findHeight = $(this).outerHeight();
					if(findHeight > largest){
						largest = findHeight;
					}  
				});

				$(document).find(div).css({"min-height":largest+"px"});
			}
		};
		
		$(window).resize(function(){
			var w = $(window).width();
			if (typeof checkw == 'undefined') checkw = w;
			if (w != checkw) {
				if($('.subpage-offer-box').length > 0){
					sameheight(".subpage-offer-box .caption");
				}
				if($('.subpage-files').length > 0){
					sameheight(".button-download");
				}
				checkw = w;
			}
		});
		
		if($('.subpage-offer-box').length > 0){
			sameheight(".subpage-offer-box .caption");
		}
		
		if($('.subpage-files').length > 0){
			sameheight(".button-download");
		}
	},
};


for(var funkcja in LEMON){
	LEMON[funkcja]();
}